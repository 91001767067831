import ourRolesTeamMember from "../../assets/img/TM.png";
import ourRolesHeadOffice from "../../assets/img/HO.png";
import ourRolesEarlyCareers from "../../assets/img/EC.png";
import ourRolesRestManagement from "../../assets/img/RM.png";

const teamMember = {
  title: "Restaurant Team Member",
  description:
    "This role’s all about creating that unforgettable Nando’s experience - from hosting and grilling to taking orders and clearing tables.",
  poster: ourRolesTeamMember,
  link: "/our-roles/restaurant-team-member",
};

const restManagement = {
  title: "Restaurant Management",
  description:
    "We’re all about progression at Nando’s. That’s why we have a few different managerial roles – from 1st Assistant Manager and 2nd Assistant Manager to General Manager and Regional Managing Director.",
  poster: ourRolesRestManagement,
  link: "/our-roles/restaurant-manager/",
};

const headOffice = {
  title: "Head Office",
  description:
    "Our teams in Central Support (or Head Office) do just that – support our restaurants to be the best they can be. Think Finance, Marketing, Property, Food & Commercial, People and Tech.",
  poster: ourRolesHeadOffice,
  link: "/our-roles/head-office",
};

const earlyCareers = {
  title: "Early Careers",
  description:
    "Early Careers looks after all of our Apprenticeship programmes, they coach and train team members who are looking to become experts in their roles. All Apprentices will gain a nationally recognised qualification.",
  poster: ourRolesEarlyCareers,
  link: "/our-roles/early-careers",
};

const roles = [teamMember, restManagement, headOffice, earlyCareers];

export default roles;
