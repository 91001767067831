import * as React from "react";
import Layout from "../../components/Layout/Layout";
import leftPattern from "../../assets/img/patterns/Our_Roles_Left_Pattern.svg";
import rightPattern from "../../assets/img/patterns/Our_Roles_Right_Pattern.svg";
import Role from "../../components/Role/Role";
import roles from "../../components/Role/roles-list";

import "./our-roles.scss";
import { SEO } from "../../components/seo";
import { descriptions } from "../../libs/seoContent";

const OurRoles = () => {
  return (
    <Layout>
      <div className="wrapper">
        <img src={leftPattern} aria-label="ourRolesLeftPattern" className="leftOurRolesPattern" />
        <img
          src={rightPattern}
          aria-label="ourRolesRightPattern"
          className="rightOurRolesPattern"
        />
        <div className="main-container">
          <h1 className="banner-title">
            Our <span className="bg-red">roles</span>
          </h1>
          <div className="two-column-grid-our-roles">
            {roles.map((role) => (
              <Role {...role} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurRoles;

export const Head = () => <SEO title="Our Roles" description={descriptions.roles} />;
